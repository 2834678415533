<template>
	<div class="user_yijian">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="技师名称">
							<el-input v-model="nameText" placeholder="请输入技师名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="联系电话">
							<el-input v-model="phoneText" placeholder="请输入联系电话" clearable></el-input>
						</el-form-item>
						<el-form-item label="报警时间">
							<el-date-picker v-model="datePicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<!-- 列表 -->
			<el-table border :data="warning_list" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-form label-position="left" inline class="demo-table-expand">
							<el-row>
								<el-col :span="24">
									<el-form-item label="服务客户：">
										<span>{{ props.row.consignee }}</span>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="订单地址：">
										<span>{{ props.row.address }}</span>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="客户电话：">
										<span>{{ props.row.mobile }}</span>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="求助时间：">
										<span>{{ props.row.add_time }}</span>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="处理时间：">
										<span>{{ props.row.deal_time }}</span>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="处理原因：">
										<span>{{ props.row.deal_reason }}</span>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="处理结果：">
										<span>{{ props.row.deal_result }}</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</template>
				</el-table-column>
				<el-table-column prop="warning_id" label="ID"></el-table-column>
				<el-table-column prop="jishi_name" label="报警人" width="100"></el-table-column>
				<el-table-column label="头像">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.gzxxz_img"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="手机号" width="150"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="jishi_address" label="所在位置" width="350"></el-table-column>
				<el-table-column label="是否处理">
					<template slot-scope="scope">
						<div>
							<el-tag type="primary" v-if="!scope.row.is_deal" disable-transitions>未处理</el-tag>
							<el-tag type="warning" v-else disable-transitions>已处理</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="150">
					<template slot-scope="scope" v-if="!scope.row.is_deal">
						<el-button type="primary" plain size="small" @click="setDeal(scope.row)">处理</el-button>
						<el-button type="primary" plain size="small" @click="setRefresh(scope.row)">刷新</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 回复对话框 -->
			<el-dialog title="预警处理" width="40%" top="10vh" :visible.sync="updateDialog" :modal-append-to-body="false">
				<el-form ref="updateForm" :model="updateForm" label-width="85px" :rules="hfRules">
					<el-row>
						<el-col :span="12">
							<el-form-item label="ID" prop="warning_id" style="text-align: left;">
								<el-input v-model.number="updateForm.warning_id" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="技师名称" prop="jishi_name" style="text-align: left;">
								<el-input v-model="updateForm.jishi_name" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="处理原因" prop="deal_reason" style="text-align: left;">
								<el-input type="textarea" :rows="4" v-model="updateForm.deal_reason" placeholder="请输入处理原因"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="处理结果" prop="deal_result" style="text-align: left;">
								<el-input type="textarea" :rows="4" v-model="updateForm.deal_result" placeholder="请输入处理结果"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
							<el-button type="default" @click="updateDialog=false">取消</el-button>
							<el-button type="primary" @click="doSubmit">确定</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		data() {
			return {
				total: 0,
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				nameText: "",
				phoneText: "",
				datePicker: "",
				warning_list: [],
				updateDialog: false,
				updateForm: {
					warning_id: '',
					deal_reason: '',
					deal_result: '',
				},
				hfRules: {
					deal_reason: [{
						required: true,
						message: "请输入处理原因",
						trigger: "blur"
					}, ],
					deal_result: [{
						required: true,
						message: "请输入处理结果",
						trigger: "blur"
					}, ],
				}
			};
		},
		components: {
			Pagination
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getWarningList();
			}
		},
		methods: {
			getWarningList() {
				let that = this;
				var url = 'warning/warning_list';
				let params = {
					jishi_name: this.nameText,
					phone: this.phoneText,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.warning_list = res.list;
						console.log(res.list);
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			search() {
				this.getWarningList();
			},
			// 重置
			reset() {
				this.searchText = "";
				this.datePicker = "";
				this.getWarningList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getWarningList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getWarningList();
			},
			setDeal(item) {
				this.updateForm.warning_id = item.warning_id;
				this.updateForm.jishi_name = item.jishi_name;
				this.updateDialog = true;
			},
			setRefresh(item) {
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'warning/refreshAddress';
					let params = {
						warning_id: item.warning_id
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('操作成功');
							that.getWarningList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}, 300);
			},
			doSubmit() {
				this.$refs.updateForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null) {
							clearTimeout(timer);
						}
						timer = setTimeout(() => {
							var url = 'warning/addOrUpdate';
							let params = that.updateForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.updateDialog = false;
									that.$refs.updateForm.resetFields();
									that.$message.success('操作成功');
									that.getWarningList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.updateDialog = false;
								that.$message.error('网络错误');
							});
						}, 300);
					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.user_yijian {
		.left {
			text-align: left;
			margin-bottom: 20px;
		}
		.demo-table-expand {
			font-size: 0;
		}
		.demo-table-expand label {
			width: 90px;
			color: #99a9bf;
		}
		.demo-table-expand .el-form-item {
			margin-right: 0;
			margin-bottom: 0;
			width: 50%;
		}
	}
</style>